import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0,7,3];

export const dictionary = {
		"/": [~8],
		"/(public)/auth/email-sent": [24,[7]],
		"/(public)/auth/find-account": [25,[7]],
		"/(public)/auth/login": [26,[7]],
		"/(public)/auth/password": [27,[7]],
		"/(public)/auth/recovery": [28,[7]],
		"/(public)/auth/start": [29,[7]],
		"/(public)/auth/verify-email": [30,[7]],
		"/(private)/(cabinet)/cases": [12,[3,4]],
		"/(private)/(cabinet)/cases/create-success": [19,[3,4]],
		"/(private)/(cabinet)/cases/create": [14,[3,4,5]],
		"/(private)/(cabinet)/cases/create/details": [15,[3,4,5]],
		"/(private)/(cabinet)/cases/create/lyft-redirect": [16,[3,4,5]],
		"/(private)/(cabinet)/cases/create/service": [17,[3,4,5]],
		"/(private)/(cabinet)/cases/create/story": [18,[3,4,5]],
		"/(private)/(cabinet)/cases/[caseId]": [13,[3,4]],
		"/(private)/details/location-and-language": [21,[3,6]],
		"/(private)/details/name-and-phone": [22,[3,6]],
		"/(private)/details/tlc": [23,[3,6]],
		"/(common)/info/faq": [9,[2]],
		"/(common)/info/privacy-policy": [10,[2]],
		"/(common)/info/terms-of-use": [11,[2]],
		"/(private)/(cabinet)/profile": [20,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';